import { getSubscriptionPrice } from "@/utils/price.ts"

export const homeData = {
    howItsWork: {
        title: "Comment ça marche ?",
        data: [
            {
                icon: "ic-search-2",
                content: "<strong>Recherchez un établissement</strong> Coiffeur, spa, restaurant... près de chez vous",
            },
            {
                icon: "ic-note",
                content: `<strong>Inscrivez-vous</strong> pour 3 jours pour 1ct, puis ${getSubscriptionPrice()}€ TTC ${
                    import.meta.env.VITE_VERTICALE !== "resto" ? "maximum" : ""
                } par mois, annulable à tout moment`,
            },
            {
                icon: "ic-woment",
                content: "<strong>Un assistant de la conciergerie</strong> s'occupe de votre réservation",
            },
            {
                icon: "ic-phone-resto",
                content: "<strong>Vous recevez la confirmation</strong> sur votre téléphone",
            },
        ],
    } as {
        title: string
        data: { icon: string; content: string }[]
    },
    principalSpecific: {
        title: "Réservez votre établissement local en toute confiance",
        description:
            "Nous travaillons avec des conciergeries spécialisées pour gérer vos réservations dans les meilleurs établissements locaux, tels que coiffeurs, spas, et bien plus encore. Annulation sans frais et satisfaction garantie. Nos partenaires vous assurent une expérience relaxante et sans effort.",
        infoBar:
            "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par l'établissement (coiffeur, spa, etc.). Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives validées.",
        image: "/images/hair-cutting.png",
        imageClasses: "bg-[position:73%]",
        advantages: [
            '<span class="text-blue-500">+160 000</span> établissements disponibles',
            '<span class="text-blue-500">+3000 villes</span> en France',
            '<span class="text-blue-500">99%</span> de satisfaction client',
            'Assistance <span class="text-blue-500">7j/7</span>',
        ],
    } as {
        title: string
        description: string
        image: string
        imageClasses: string
        infoBar: string
        advantages: string[]
    },
    principalGeneral: {
        title: "L’offre conciergerie",
        label: "Un concierge dédié pour simplifier votre quotidien",
        description:
            "Grâce à nos conciergeries partenaires, bénéficiez d’un concierge personnel disponible <strong>{{assistanceTime}} heures par mois,</strong> 7j/7, par email, chat ou téléphone. Ce professionnel s’occupe de tout : réserver un taxi, un restaurant, un coiffeur, organiser vos sorties, vos voyages, ou encore trouver un plombier ou un jardinier.<br/>Il prend en charge toutes vos demandes rapidement et efficacement, vous permettant de gagner du temps et de simplifier chaque aspect de votre quotidien. Avec ce service, profitez d’une assistance sur mesure, où que vous soyez.",
        advantages: [
            '<span class="text-blue-500">+1 500 000 services </span>',
            '<span class="text-blue-500">+60 000 villes</span> couvertes',
            '<span class="text-blue-500">130 catégories</span> de service',
            'Assistance <span class="text-blue-500">7j/7</span>',
        ],
        image: "/images/man-sitting-on-sofa.svg",
    } as {
        title: string
        label: string
        description: string
        advantages: string[]
        image: string
    },
    clickCard: {
        title: "Plus besoin d’appeler, tout est à portée de clic.",
        description:
            "Gagnez du temps et simplifiez vos réservations. Les assistants de la conciergerie s'occupent de tout pour vous, où que vous soyez. Faites votre demande, et laissez-les gérer chaque étape de la réservation.",
        buttonText: "Lancer une réservation",
        bulletPoints: [
            "<strong>Simple</strong> : Réservez en 2 clics, sans avoir à appeler.",
            "<strong>Instantané</strong> : Derrière chaque demande, un agent prend en charge tout type de réservation dans la minute.",
            "<strong>Proactif</strong> : Si une réservation n'est pas disponible, un agent trouve une alternative pour vous.",
        ],
        image: "/images/woman-look-phone.png",
    } as {
        title: string
        description: string
        buttonText: string
        bulletPoints: string[]
        image: string
    },
    assistantCard: {
        title: "Nos assistants sont à votre service, à chaque étape de votre besoin",
        description:
            "Les assistants de la conciergerie prennent en charge toutes vos réservations, du début à la fin. Que ce soit pour trouver le meilleur service, gérer les imprévus ou vous proposer des solutions alternatives, ils veillent à ce que vous n'ayez rien à gérer. Vous faites votre demande, ils s’occupent de tout en toute transparence. Vous êtes informé par SMS, et ils sont joignables 7j/7.",
        image: "/images/woman-look-laptop.png",
    } as {
        title: string
        description: string
        image: string
    },
    whatsAppCard: {
        title: "Des assistants personnels sont aussi disponible sur WhatsApp",
        description:
            "Simplifiez toutes vos réservations grâce à l'assistant de la conciergerie sur WhatsApp, accessible 24h/24 et 7j/7. Indiquez simplement ce dont vous avez besoin, et ils se chargent du reste. Avec des confirmations en temps réel, tout se fait directement via WhatsApp, sans effort.",
        image: "/images/work-on-computer.png",
        buttonText: "En savoir plus",
    } as {
        title: string
        description: string
        image: string
        buttonText: string
    },
    promotionCard: {
        title: "Profitez d'une offre sans engagement",
        description: `<strong>3 jours d’essai pour 1ct puis ${getSubscriptionPrice()} euros TTC ${
            import.meta.env.VITE_VERTICALE !== "resto" ? "maximum" : ""
        }{import.meta.env.VITE_VERTICALE !== "resto" ? "maximum" : ""} par mois</strong>, bénéficiez de la gestion de toutes vos réservations pour tout type de service. Abonnez-vous en toute confiance : sans engagement, annulation possible à tout moment, satisfaction garantie.`,
        image: "/images/woman.jpeg",
        bulletPoints: [
            "<strong>Période d’essai pour 1ct</strong> : Découvrez le service sans aucun engagement.",
            "<strong>Sans engagement</strong> : Réservez tout ce que vous voulez sans contrainte.",
            "<strong>Satisfaction garantie</strong> : Si vous n'êtes pas satisfait, annulez quand vous voulez et profitez de la politique de remboursement.",
        ],
        buttonText: "Lancer une réservation",
    } as {
        title: string
        description: string
        image: string
        bulletPoints: string[]
        buttonText: string
    },
    listServices: {
        title: "Nos assistants facilitent toutes vos réservations, où que vous soyez",
        images: [
            {
                image: "images/hairdesser.jpeg",
                name: "Coiffeur",
            },
            {
                image: "images/moving-2.png",
                name: "Déménagement",
            },
            {
                image: "images/transport-2.jpeg",
                name: "Transports",
            },
            {
                image: "images/spa.jpeg",
                name: "Spa",
            },
        ],
    } as {
        title: string
        images: {
            image: string
            name: string
        }[]
    },
}
